<template>
  <div class="restaurants">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate 
    style="background: #92f22a" 
    :title="title.titlePage" 
    :subtitle="title.subtitlePage"
    :img="title.img"/>
    
    <InformationsTemplate :card="popular"/>
    <p><router-link :to="{name: 'refugees-menu-PT'}">{{$t('restaurant.linkPage')}}</router-link></p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy
  },
  data(){
    return{
       title: {
        titlePage: this.$t('restaurant.titlePage'),
        subtitlePage: this.$t('restaurant.subtitlePage'),
        img: "restaurant.png"
      },
      popular: {
        title: this.$t("restaurant.popularTitle"),
        text: this.$t("restaurant.popularText"),
        link: "https://www.google.com.br/maps/search/restaurante+popular+rio+de+janeiro/@-22.8512578,-43.3460284,12z/data=!3m1!4b1",
        linkName: this.$t("restaurant.popularLinkName")
      }
    }
  }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
p{
  margin: 0 0 50px 120px;
}
</style>